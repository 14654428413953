import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import {incidentRiderInjuryComplications} from '../util/values';
import {incidentRiderInjuryCard} from '../util/values';
import { hasPrivileges,hasExactPrivilege } from "../util/auth";

//We'll be adding involved riders in this step
// An involved rider is stored as follows
/* {
    "rider_id": 203,
    "incident_rider_implications": "DNF",
    "incident_rider_injury": "Broken Collarbone Right",
    "incident_rider_created_by": 1,
    "incident_rider_notes": ""
    "incident_rider_severity": 0
}
*/
export const IncidentCreatorStepTwo = (props)=>{
    //Teams
    let teams = props.teams;
    let riders = props.riders;

    var [newRiderState, setNewRiderState] = useState({
        "rider_id": "",
        "rider_name": "", 
        "rider_team": "",
        "incident_rider_implications": null,
        "incident_rider_injury": null,
        "incident_rider_notes": null,
        "incident_rider_severity": null,
		"incident_rider_card": null
    })

    let [involvedRiders, setInvolvedRiders] = useState(null)

    useEffect(()=>{
        setInvolvedRiders(props.involvedRiders)
    }, [props.involvedRiders])

    if(props.currentStep!==2){
        return null
    }

    var updateNewRiderState = (key, value)=>{
        setNewRiderState({...newRiderState, [key]:value})
        console.log(newRiderState)
    }

    var addNewRider = ()=>{
        if(newRiderState.rider_id!==""){
            props.riderCallback(newRiderState)
            setNewRiderState({
                "rider_id": "",
                "rider_name": "",
                "rider_team": "",
                "incident_rider_implications": null,
                "incident_rider_injury": null,
                "incident_rider_notes": null,
                "incident_rider_severity": null,
				"incident_rider_card": null
            })
        }
        else{
            alert("A rider name should be provided (at minimum).")
        }
    }
    
    var riderDropdownChanged = option => {
        const {label, value, team}=option
        setNewRiderState({...newRiderState, rider_id: value, rider_name: label, rider_team: team})
    }

    var newRiderCallback = (evt)=>{
        const {name, value}=evt.target
        updateNewRiderState(name, value)
    }

    return(
        <div className='subFormPart'>
            <h4>Involved riders</h4>
            <p>In this step you can select one or multiple riders and attach them to the reported incident. If no riders were involved in the incident you can also skip this step.</p>
            <div id="select-riders" className='selection-area'>
                <h5>Select a rider</h5>
                <div className='form-group'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <Select options={teams} defaultValue="" onChange={props.teamCallback}/>
                        </div>
                        <div className='col-sm-6'>
                            <Select options={riders} defaultValue={newRiderState.rider_id} onChange={riderDropdownChanged}/>
                        </div>
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor="incident_rider_notes">Additional notes for the rider's injury</label>
                    <input type="text" className='form-control' name="incident_rider_notes" value={newRiderState['incident_rider_notes']} onChange={newRiderCallback}/>
                </div>
                {hasPrivileges('doctor')?<div className='form-group'>
                    <label htmlFor="incident_rider_injury">Sustained injury from incident</label>
                    <input type="text" className='form-control' name="incident_rider_injury" value={newRiderState['incident_rider_injury']} onChange={newRiderCallback}/>
                </div>:""}
                <div className='form-group row'>
                    <div className="col col-sm-6">
                        <label htmlFor="incident_rider_injury">Implications of incident on rider</label>
                        <select name="incident_rider_implications" defaultValue="NONE" className='custom-select' value={newRiderState['incident_rider_implications']} onChange={newRiderCallback}>
                            {incidentRiderInjuryComplications.map((option, i)=>option)}
                        </select>
                    </div>
                    <div className="col col-sm-6">
                        <label htmlFor="incident_rider_severity">Severity of the rider's injury (0-10) <span className="badge badge-info">{newRiderState['incident_rider_severity']}</span></label>
                        <input placeholder='Amount of spectators' id='incident_rider_severity'
                                            name="incident_rider_severity"
                                            value={props.incident_rider_severity} 
                                            step='1' 
                                            onChange={newRiderCallback}
                                            className='custom-range' type='range'
                                            min='0' max='10'/>
                    </div>
                </div>
				<div className="form-group">
                        <label htmlFor="incident_rider_card">Sanction for rider</label>
                        <select name="incident_rider_card" defaultValue="NONE" className='custom-select' value={newRiderState['incident_rider_card']} onChange={newRiderCallback}>
                            {incidentRiderInjuryCard.map((option, i)=>option)}
                        </select>
                </div>
                <div className='form-group'>
                    <a className='btn btn-secondary' onClick={addNewRider}>Add rider</a>
                </div>
            </div>
            <div id="selected-incident-riders" className='selected-area'>
                <h5>Involved riders</h5>
                <div id="involved-riders">
                    <table>
                        <tr>
                            <th>Rider</th>
                            <th>Team</th>
                             {hasPrivileges('doctor')?<th>Sustained injury from incident</th>:""}
                            <th>Implications</th>
                            <th>Extra notes</th>
                            <th>Severity</th>
							<th>Sanction</th>
                            <th>Actions</th>
                        </tr>
                    {involvedRiders.map((involvedRider)=>{
                        return (
                            <tr className='involvedRiderEntry' key={involvedRider.rider_id}>
                                <td>{involvedRider.rider_name}</td>
                                <td>{involvedRider.rider_team}</td>
                                {hasPrivileges('doctor')?<td><input type="text" className='form-control' name="incident_rider_injury" defaultValue={involvedRider.incident_rider_injury} onChange={(evt)=>{props.editRiderCallback(evt, involvedRider.rider_id)}}/></td>:""}
                                <td>
                                    <select name="incident_rider_implications" className='custom-select' defaultValue={involvedRider.incident_rider_implications} onChange={(evt)=>{props.editRiderCallback(evt, involvedRider.rider_id)}}>
                                        {incidentRiderInjuryComplications.map((option, i)=>option)}
                                    </select>
                                </td>
                                <td><input type="text" className='form-control' name="incident_rider_notes" defaultValue={involvedRider.incident_rider_notes} onChange={(evt)=>{props.editRiderCallback(evt, involvedRider.rider_id)}}/></td>
                                <td><input placeholder='Amount of spectators' id='involved_incident_rider_severity'
                                            name="incident_rider_severity"
                                            value={involvedRider.incident_rider_severity} 
                                            step='1' 
                                            onChange={(evt)=>{props.editRiderCallback(evt, involvedRider.rider_id)}}
                                            className='custom-range' type='range'
                                            min='0' max='10'/></td>
								<td>
                                    <select name="incident_rider_card" className='custom-select' defaultValue={involvedRider.incident_rider_card} onChange={(evt)=>{props.editRiderCallback(evt, involvedRider.rider_id)}}>
                                        {incidentRiderInjuryCard.map((option, i)=>option)}
                                    </select>
                                </td>			
                                <td><a className="btn btn-danger" onClick={()=>{props.removeRiderCallback(involvedRider.rider_id)}}>Delete</a></td>
                            </tr>
                        )
                    })}
                    </table>
                </div>
            </div>
        </div>
    )
}