import { Cancel, Edit, Save } from "@material-ui/icons";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { hasPrivileges,hasExactPrivilege } from "../util/auth";
import {incidentRiderInjuryComplications} from '../util/values';
import {incidentRiderInjuryCard} from '../util/values';

export const Rider = (props) => {
    const rider = props.rider;
    return (
        <div className='rider' key={`rider_${rider.uci_id}`}>
            <p>{rider.first_name} <b>{rider.last_name}</b></p>
        </div>
    );
}

export const RiderInjury = (props) => {
    var [implicationsEditable, setImplicationsEditable] = useState(false);
    var [injuryEditable, setInjuryEditable] = useState(false);
	var [cardEditable, setCardEditable] = useState(false);
    var [injuryInput, setInjuryInput] = useState(null);

    const rider = props.rider.rider;
    const id = props.key;
    const implications = props.rider.incident_rider_implications;
    const injury = props.rider.incident_rider_injury;
	const card = props.rider.incident_rider_card;
    const deleteHandler = props.deleteHandler;

    if(hasExactPrivilege('commissaire')){
        return (
            <tr className='incidentRider' key={`${id}`}>
                <td className='riderName' key={`rider_incident_name${rider.uci_id}`}>{rider.first_name} <b>{rider.last_name}</b></td>
                <td className='riderUciId' key={`header_rider_uciid${rider.uci_id}`}>{rider.uci_id}</td>
                <td className='riderTeam' key={`header_rider_team${rider.uci_id}`}>{rider.resolved_team?rider.resolved_team.team_name:"UNKNOWN"}</td>
                {implicationsEditable===false?
                    <td className='riderInjuryImplications' onClick={()=>setImplicationsEditable(true)} key={`rider_incident_injury_implications${rider.uci_id}`}>{implications}<span className='btn' onClick={()=>setImplicationsEditable(true)}><Edit/></span></td>
                    :
                    <td className='riderInjuryImplications'>
                        <select name="incident_rider_implications" defaultValue="NONE" className='custom-select' value={implications} onChange={e=>{props.updateImplications(rider.rider_id, e.target.value); setImplicationsEditable(false);}}>
                            {incidentRiderInjuryComplications.map((option, i)=>option)}
                        </select>
                        <span className='btn disable-edit' onClick={()=>setImplicationsEditable(false)}><Cancel/></span>
                    </td>
                }
				{cardEditable===false?
                    <td className='riderInjuryCard' onClick={()=>setCardEditable(true)} key={`rider_incident_injury_card${rider.uci_id}`}>{card}<span className='btn' onClick={()=>setCardEditable(true)}><Edit/></span></td>
                    :
                    <td className='riderInjuryCard'>
                        <select name="incident_rider_card" defaultValue="NONE" className='custom-select' value={card} onChange={e=>{props.updateCard(rider.rider_id, e.target.value); setCardEditable(false);}}>
                            {incidentRiderInjuryCard.map((option, i)=>option)}
                        </select>
                        <span className='btn disable-edit' onClick={()=>setCardEditable(false)}><Cancel/></span>
                    </td>
                }
                <td className='riderInjuryActions' key={`rider_incident_injury_actions${rider.uci_id}`}>
                    {hasPrivileges('commissaire')?<span class='btn btn-danger' onClick={deleteHandler}>Delete</span>:""}
                </td>
            </tr>
        );
    }
	else if(hasExactPrivilege('admin')){
        return (
            <tr className='incidentRider' key={`${id}`}>
                <td className='riderName' key={`rider_incident_name${rider.uci_id}`}>{rider.first_name} <b>{rider.last_name}</b></td>
                <td className='riderUciId' key={`header_rider_uciid${rider.uci_id}`}>{rider.uci_id}</td>
                <td className='riderTeam' key={`header_rider_team${rider.uci_id}`}>{rider.resolved_team?rider.resolved_team.team_name:"UNKNOWN"}</td>
                {injuryEditable===false?
                    <td className='riderInjuryType' key={`rider_incident_injury_type${rider.uci_id}`} onClick={()=>setInjuryEditable(true)} >
                        {injury}
                        <span className='btn' onClick={()=>{setInjuryInput(injury);setInjuryEditable(true)}}><Edit/></span>
                    </td>:
                    <td className='riderInjuryType editable' key={`rider_incident_injury_type${rider.uci_id}`}>
                        <div className='riderInjuryInput' key={`rider_incident_injury_type_value_${rider.uci_id}`}>
                            <textarea className='form-control' value={injuryInput} onChange={(e)=>{setInjuryInput(e.target.value)}}/>
                        </div>
                        <div className='riderInjuryButtons'>
                        <Button className='btn btn-success' onClick={()=>{props.updateInjury(rider.rider_id, injuryInput);setInjuryEditable(false)}}><Save/></Button>
                        <Button className='btn btn-secondary' onClick={()=>setInjuryEditable(false)}><Cancel/></Button>
                        </div>
                    </td>
                }
                {implicationsEditable===false?
                    <td className='riderInjuryImplications' onClick={()=>setImplicationsEditable(true)} key={`rider_incident_injury_implications${rider.uci_id}`}>{implications}<span className='btn' onClick={()=>setImplicationsEditable(true)}><Edit/></span></td>
                    :
                    <td className='riderInjuryImplications'>
                        <select name="incident_rider_implications" defaultValue="NONE" className='custom-select' value={implications} onChange={e=>{props.updateImplications(rider.rider_id, e.target.value); setImplicationsEditable(false);}}>
                            {incidentRiderInjuryComplications.map((option, i)=>option)}
                        </select>
                        <span className='btn disable-edit' onClick={()=>setImplicationsEditable(false)}><Cancel/></span>
                    </td>
                }
				{cardEditable===false?
                    <td className='riderInjuryCard' onClick={()=>setCardEditable(true)} key={`rider_incident_injury_card${rider.uci_id}`}>{card}<span className='btn' onClick={()=>setCardEditable(true)}><Edit/></span></td>
                    :
                    <td className='riderInjuryCard'>
                        <select name="incident_rider_card" defaultValue="NONE" className='custom-select' value={card} onChange={e=>{props.updateCard(rider.rider_id, e.target.value); setCardEditable(false);}}>
                            {incidentRiderInjuryCard.map((option, i)=>option)}
                        </select>
                        <span className='btn disable-edit' onClick={()=>setCardEditable(false)}><Cancel/></span>
                    </td>
                }
                <td className='riderInjuryActions' key={`rider_incident_injury_actions${rider.uci_id}`}>
                    {hasPrivileges('commissaire')?<span class='btn btn-danger' onClick={deleteHandler}>Delete</span>:""}
                </td>
            </tr>
        );		
	}
	else if(hasExactPrivilege('doctor')){
        return (
            <tr className='incidentRider' key={`${id}`}>
                <td className='riderName' key={`rider_incident_name${rider.uci_id}`}>{rider.first_name} <b>{rider.last_name}</b></td>
                <td className='riderUciId' key={`header_rider_uciid${rider.uci_id}`}>{rider.uci_id}</td>
                <td className='riderTeam' key={`header_rider_team${rider.uci_id}`}>{rider.resolved_team?rider.resolved_team.team_name:"UNKNOWN"}</td>
                {injuryEditable===false?
                    <td className='riderInjuryType' key={`rider_incident_injury_type${rider.uci_id}`} onClick={()=>setInjuryEditable(true)} >
                        {injury}
                        <span className='btn' onClick={()=>{setInjuryInput(injury);setInjuryEditable(true)}}><Edit/></span>
                    </td>:
                    <td className='riderInjuryType editable' key={`rider_incident_injury_type${rider.uci_id}`}>
                        <div className='riderInjuryInput' key={`rider_incident_injury_type_value_${rider.uci_id}`}>
                            <textarea className='form-control' value={injuryInput} onChange={(e)=>{setInjuryInput(e.target.value)}}/>
                        </div>
                        <div className='riderInjuryButtons'>
                        <Button className='btn btn-success' onClick={()=>{props.updateInjury(rider.rider_id, injuryInput);setInjuryEditable(false)}}><Save/></Button>
                        <Button className='btn btn-secondary' onClick={()=>setInjuryEditable(false)}><Cancel/></Button>
                        </div>
                    </td>
                }
                {implicationsEditable===false?
                    <td className='riderInjuryImplications' onClick={()=>setImplicationsEditable(true)} key={`rider_incident_injury_implications${rider.uci_id}`}>{implications}<span className='btn' onClick={()=>setImplicationsEditable(true)}><Edit/></span></td>
                    :
                    <td className='riderInjuryImplications'>
                        <select name="incident_rider_implications" defaultValue="NONE" className='custom-select' value={implications} onChange={e=>{props.updateImplications(rider.rider_id, e.target.value); setImplicationsEditable(false);}}>
                            {incidentRiderInjuryComplications.map((option, i)=>option)}
                        </select>
                        <span className='btn disable-edit' onClick={()=>setImplicationsEditable(false)}><Cancel/></span>
                    </td>
                }
				{cardEditable===false?
                    <td className='riderInjuryCard' onClick={()=>setCardEditable(true)} key={`rider_incident_injury_card${rider.uci_id}`}>{card}<span className='btn' onClick={()=>setCardEditable(true)}><Edit/></span></td>
                    :
                    <td className='riderInjuryCard'>
                        <select name="incident_rider_card" defaultValue="NONE" className='custom-select' value={card} onChange={e=>{props.updateCard(rider.rider_id, e.target.value); setCardEditable(false);}}>
                            {incidentRiderInjuryCard.map((option, i)=>option)}
                        </select>
                        <span className='btn disable-edit' onClick={()=>setCardEditable(false)}><Cancel/></span>
                    </td>
                }
                <td className='riderInjuryActions' key={`rider_incident_injury_actions${rider.uci_id}`}>
                    {hasPrivileges('commissaire')?<span class='btn btn-danger' onClick={deleteHandler}>Delete</span>:""}
                </td>
            </tr>
        );		
	}
    else{
        return (
            <tr className='incidentRider' key={`rider_incident_${id}`}>
                <td className='riderName' key={`rider_incident_name${rider.uci_id}`}>{rider.first_name} <b>{rider.last_name}</b></td>
                <td className='riderUciId' key={`header_rider_uciid${rider.uci_id}`}>{rider.uci_id}</td>
                <td className='riderTeam' key={`header_rider_team${rider.uci_id}`}>{rider.resolved_team?rider.resolved_team.team_name:"UNKNOWN"}</td>
                <td className='riderInjuryType' key={`rider_incident_injury_type${rider.uci_id}`}>{injury}</td>
                <td className='riderInjuryImplications' key={`rider_incident_injury_implications${rider.uci_id}`}>{implications}</td>
                {hasPrivileges('commissaire')?<td className='riderInjuryActions' key={`rider_incident_injury_actions${rider.uci_id}`}><span class='btn btn-danger' onClick={deleteHandler}>Delete</span></td>:""}
            </tr>
        );
    }
}
