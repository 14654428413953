import React, { Component } from 'react'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';

export default class home extends Component {
    render() {
        return (
            <div>
                <h1>UCI incident database</h1>
                <h3>About the incident database</h3>
                <p>The incident database and its front end application is a tool to easily view, create and modify road cycling race related incidents. The 
                    races within the incident database are automatically linked with the UCI database. An automated Twitter scraper already prepopulates the incidents
                    of the major races on the UCI calendar. Race commisaires or other UCI stakeholders can then add more incidents or modify 
                    the automatically created ones.
                </p>
                <h3>About this project</h3>
                <p>In January 2021, IDLab (Ghent University - imec) started its collaboration with the Union Cycliste Internationale (UCI)
                    on road cycling safety analysis and incident reporting. As a joint effort, a series of applications, measures and tools to 
                    analyse, prevent and understand safety related matters.</p>
                <h3>Role of IDLab within this project</h3>
                <p>The <a target='blank' href="https://s-team-ghent.github.io/">research group</a> lead by professor Steven Verstockt will have an assisting role for the road safety manager, race organizers and race officials
                    to help them understand which factors lead to safer races. The research group will mainly focus on building tools and algorithms to highlight, document
                    and analyse safety related topics such as course analysis and automated Twitter incident scraping.                    
                </p>
                <h3>Principal involved researchers</h3>
                <ul className='person-overview'>
                    <li className='person-item'>
                        <div className='person-title'>Prof. Steven Verstockt</div>
                        <div className='person-picture'><img alt='thumbnail-steven' style={{height: '150px', width: 'auto'}} src='https://ai.ugent.be/assets/img/people/StevenVerstockt.jpg'/></div>
                        <div className='person-links'>
                            <a href='https://www.linkedin.com/in/steven-verstockt-b416415/'><LinkedInIcon/></a>
                            <a href='mailto:steven.verstockt@ugent.be'><EmailIcon/></a>
                        </div>
                    </li>
                    <li className='person-item'>
                        <div className='person-title'>Drs. Robbe Decorte</div>
                        <div className='person-picture'><img alt='thumbnail-jelle' style={{height: '150px', width: 'auto'}} src='http://www.elis.ugent.be/pers/thumbnails/RD007.jpeg'/></div>
                        <div className='person-links'>
                            <a href='https://www.linkedin.com/in/robbedec/'><LinkedInIcon/></a>
                            <a href='mailto:robbe.decorte@ugent.be'><EmailIcon/></a>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}
