import React, { useState, useEffect, useRef } from 'react'
import axiosInstance from '../api/axios';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

export default function Login(props){
    var [username, setUsername] = useState(undefined);
    var [password, setPassword] = useState(undefined);
    var [access_token, setAccess_token]= useState(null);
    var [refresh_token, setRefresh_token] = useState(null);
    var [loggedIn, setLoggedIn] = useState(null);
    var [role, setRole] = useState(null);
    var [prevPath, setPrevPath] = useState(null);
    var [message, setMessage] = useState(null)
    const recaptchaRef = useRef()

    const history = useHistory();

    useEffect(()=>{
        if(localStorage.getItem("access_token")){
            setAccess_token(localStorage.getItem('access_token'));
            setRefresh_token(localStorage.getItem('refresh_token'));
            setUsername(localStorage.getItem('username'));
            setRole(localStorage.getItem('role'))
            setLoggedIn(true);
        }
        else{
            setLoggedIn(false);
        }
        if(props.location.state && props.location.state.prevPath){
            setPrevPath(props.location.state.prevPath)
        }
    },[props.location.state])

    const changeUserName = (event) =>{
        setUsername(event.target.value);
    }

    const changePassword = (event) =>{
        setPassword(event.target.value);
    }

    const setMessageTimeout = (msg)=>{
        setMessage(msg)
        setTimeout(()=>{
            setMessage("")
        }, 5000)
    }

    const handleSubmit = (event) => {
            event.preventDefault();
            var recaptchaValue = recaptchaRef.current.getValue();
            if(recaptchaValue){
                axiosInstance.post('/login', {
                    username: username,
                    password: password
                }).then((response)=>{
                    axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access_token;
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('refresh_token', response.data.refresh_token);
                    localStorage.setItem('username', username);
                    //We will determine which role the user has
                    if(response.data.is_admin===1){
                        localStorage.setItem('role', 'admin')
                        setRole('admin')
                    }
					else if(response.data.is_doctor===1){
                        localStorage.setItem('role', 'doctor')
                        setRole('doctor')
                    }
                    else if(response.data.is_commissaire===1){
                        localStorage.setItem('role', 'commissaire')
                        setRole('commissaire')
                    }
                    else if(response.data.is_staff===1){
                        localStorage.setItem('role', 'staff')
                        setRole('staff')
                    }
                    setAccess_token(response.data.access_token);
                    setRefresh_token(response.data.refresh_token);
                    setLoggedIn(true)
                    if(prevPath){
                        localStorage.setItem('redirect_to', prevPath);
                    }
                    window.location.reload()
                }).catch(function (error) {
                    // handle error
                    console.log(error);
                    setMessageTimeout("Invalid credentials provided, retry")
                    recaptchaRef.current.reset()
                })
            }
            else{
                setMessageTimeout("You must check the recaptcha to login.")
            }
    }

    if(!loggedIn){
        return (
            <div>
                <h1>Login page</h1>
                <div className='message'>{message}</div>
                <div className='loginform'>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <label>Username</label>
                            <input className="form-control" name="username" type="text" value={username} onChange={changeUserName}/>
                        </div>
                        <div className='form-group'>
                            <label>Password</label>
                            <input className="form-control" name="password" type="password" value={password} onChange={changePassword}/>
                        </div>
                        <div className='form-group'>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LeFE9oaAAAAAP108DVotl6TG2Hk12alryuEF1hx"
                            />
                        </div>
                        <div className='form-group'>
                            <input className='btn btn-theme' type="submit" value="Log in"/>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    else{
        if(localStorage.getItem('redirect_to')){
            var path = localStorage.getItem('redirect_to')
            localStorage.removeItem('redirect_to')
            history.push(path)
        }
        return ( 
            <div>
                <h3>Login info</h3>
                <p><b>User name </b>{username}</p>
                <p><b>Access token </b>{access_token}</p>
                <p><b>Refresh token </b>{refresh_token}</p>
                <p><b>User role </b>{role}</p>
            </div>
        )
    }
}
