import { Redirect, Route } from "react-router-dom";

export const isLoggedIn = () => {
    console.log("Check requires auth.")
    if (!localStorage.getItem('access_token')) {
        return false;
    }
    return true;
};

export const hasPrivileges = (privilege) =>{
  console.log("Checking required privileges")
  if(!localStorage.getItem('access_token')){
    return false
  }
  var user_role = localStorage.getItem('role')
  var role_hierarchy = ['admin','doctor','commissaire','staff','peasant']
  //Check if hierarchy of user_role is sufficiant for required privilege
  console.log("Privilege required "+role_hierarchy.indexOf(privilege));
  console.log("Has privilege "+role_hierarchy.indexOf(user_role));
  if(role_hierarchy.indexOf(user_role)!==-1 && role_hierarchy.indexOf(privilege) !== -1 && role_hierarchy.indexOf(user_role)<=role_hierarchy.indexOf(privilege)){
    return true;
  }
  return false;
}

export const hasExactPrivilege = (privilege) =>{
  console.log("Checking exact required privileges")
  if(!localStorage.getItem('access_token')){
    return false
  }
  var user_role = localStorage.getItem('role')
  return user_role===privilege;
}

export const ProtectedRoute = ({ component: Comp, loggedIn, path, ...rest }) => {
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          return loggedIn ? <Comp {...props} /> : <Redirect to={{pathname: "/login", state: {prevPath: props.location.pathname}}} />;
        }}
      />
    );
};  

export const ProtectedPrivilegedRoute = ({ component: Comp, loggedIn, path, requiredPrivilege, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return loggedIn && hasPrivileges(requiredPrivilege) ? <Comp {...props} /> : <Redirect to="/unauthorized" />;
      }}
    />
  );
};

export const ProtectedExactPrivilegedRoute = ({ component: Comp, loggedIn, path, requiredPrivilege, ...rest }) => {
  var role = localStorage.getItem('role')
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return loggedIn && role===requiredPrivilege ? <Comp {...props} /> : <Redirect to="/unauthorized" />;
      }}
    />
  );
};